<template>

<section>

    <v-col>
        <h2 class="mx-5">{{this.userName}}</h2>
        <h5 class="mx-5">{{this.userRole}}</h5>
    </v-col>
        
    <v-col>
        <v-data-table
            :headers="headers"
            :items="branches"
            :calculate-widths="true"
            class="ph-nonex-datatable"
            :search = "search"
            >
            <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-layout>
                        <v-spacer></v-spacer>
                        <v-flex md4 class="search">
                            <v-text-field
                                v-model="search"
                                placeholder="Search"
                                label="Search"
                                single-line
                                hide-details
                            ></v-text-field> 
                        </v-flex>
                    </v-layout>
                </v-toolbar>
                <v-toolbar flat color="white">
                    <v-layout>
                        <v-spacer></v-spacer>
                        <v-flex>
                        <v-btn style="float:right;" color="primary" small @click="addNewHandle"><v-icon>mdi-plus</v-icon>Add New Entity</v-btn>
                        <v-btn class="mx-5" style="float:right;" color="primary" small @click="addHandled"><v-icon>mdi-plus</v-icon>Add Encoded Entity</v-btn>
                        </v-flex>
                    </v-layout>
                </v-toolbar>
            </template>
            <template v-slot:[`item.action`]="{item}">
                <div>
                    <v-btn
                        v-if="item.prospective_number"
                        text
                        color="error"
                        @click="deleteBranch(item)"
                        >
                        <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>

                    <v-btn
                        v-if="!item.prospective_number"
                        text
                        color="primary"
                        @click="editNewBranch(item)"
                        >
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>

                    <v-btn
                        v-if="!item.prospective_number"
                        text
                        color="error"
                        @click="deleteNewBranch(item)"
                        >
                        <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
    </v-col>

    <v-dialog
      v-model="handledDialog"
      width="600px"
      persistent
    >
        <v-card>
            <v-card-title>
            <span class="text-h5" id="dialog_title">Add Encoded Entity</span>
            </v-card-title>
            <v-card-text>
                <v-container>

                    <v-row>
                        <v-col
                            cols="12"
                            >
                            <v-autocomplete
                                class="mt-5"
                                dense
                                solo
                                :items="branchList"
                                item-text="branch_name"
                                return-object
                                placeholder="Entity Location"
                                v-model="to_add_branch"
                                @change="fillEncodedDetails"
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-col cols="12">
                        <v-text-field
                            label="Entity Name"
                            v-model="encoded_entity_name"
                            outlined
                            hide-details="auto"
                            dense
                            readonly
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <v-text-field
                            label="SP Number"
                            v-model="encoded_sp"
                            outlined
                            hide-details="auto"
                            dense
                            readonly
                        ></v-text-field>
                    </v-col>

              </v-container>
          </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    outlined color="primary"
                    text
                    @click="cancel"
                    small
                >
                    Cancel
                </v-btn>
                <v-btn v-if="to_add_branch"
                    color="success"
                    @click="submit"
                    small
                >
                    Add Entity
                </v-btn>
                <v-btn v-else disabled small>
                    Add Entity
                </v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>

    <v-dialog
      v-model="handledNewDialog"
      width="600px"
      persistent
    >
        <v-card>
            <v-card-title>
            <span class="text-h5" id="dialog_title">Add New Entity</span>
            </v-card-title>
            <v-card-text>
                <v-container>

                    <v-col cols="12">
                        <v-text-field
                            label="Entity Name"
                            v-model="new_entity_name"
                            outlined
                            hide-details="auto"
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            label="Entity Location"
                            v-model="new_entity_location"
                            outlined
                            hide-details="auto"
                            dense
                        ></v-text-field>
                    </v-col>

              </v-container>
          </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    outlined color="primary"
                    text
                    @click="cancelNew"
                    small
                >
                    Cancel
                </v-btn>
                <v-btn v-if="new_entity_location && new_entity_name"
                    color="success"
                    @click="submitNew"
                    small
                >
                    Add Entity
                </v-btn>
                <v-btn v-else disabled small>
                    Add Entity
                </v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>

    <v-dialog
      v-model="editNewDialog"
      width="600px"
      persistent
    >
        <v-card>
            <v-card-title>
            <span class="text-h5" id="dialog_title">Edit Entity</span>
            </v-card-title>
            <v-card-text>
                <v-container>

                    <v-col cols="12">
                        <v-text-field
                            label="Entity Name"
                            v-model="edit_entity_name"
                            outlined
                            hide-details="auto"
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            label="Entity Location"
                            v-model="edit_entity_location"
                            outlined
                            hide-details="auto"
                            dense
                        ></v-text-field>
                    </v-col>

              </v-container>
          </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    outlined color="primary"
                    text
                    @click="cancelEdit"
                    small
                >
                    Cancel
                </v-btn>
                <v-btn v-if="edit_entity_name && edit_entity_location"
                    color="success"
                    @click="submitEdit"
                    small
                >
                    Update Entity
                </v-btn>
                <v-btn v-else disabled small>
                    Update Entity
                </v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>

    <v-dialog
        v-model="deleteEncoded"
        persistent
        max-width="400"
        >
        <v-card>
            <v-card-title>
                Remove Encoded Entity
            </v-card-title>
            <v-card-text>
                <br>
                <p style="font-size: 15px;">Remove entity? You can't undo this.</p>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="cancelDeleteEncoded"
                    >
                    Cancel
                </v-btn>
                <v-btn
                    text
                    @click="confirmDeleteEncoded"
                    >
                    Confirm
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog
        v-model="deleteNewEntity"
        persistent
        max-width="400"
        >
        <v-card>
            <v-card-title>
                Remove New Entity
            </v-card-title>
            <v-card-text>
                <br>
                <p style="font-size: 15px;">Remove entity? You can't undo this.</p>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="cancelNewDialog"
                    >
                    Cancel
                </v-btn>
                <v-btn
                    text
                    @click="confirmDeleteNewEntity"
                    >
                    Confirm
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</section>


</template>


<script>



export default {
    data() {
		return	{
            search: '',
			userName: '',
            userEmail: '',
			userRole: '',
            branches: [],
            branchList: [],
            headers: [
                {
                    text: 'SP Number',
                    sortable: true,
                    value: 'prospective_number'
                },
                {
                    text: 'Entity Name',
                    sortable: true,
                    value: 'account_name'
                },
                {
                    text: 'Entity Location',
                    sortable: true,
                    value: 'branch_name'
                },
                {
                    text: 'Action',
                    sortable: false,
                    value: 'action',
                    align: 'center'
                }
            ],
            handledDialog: false,
            handledNewDialog: false,
            editNewDialog: false,
            to_add_branch:'',
            new_entity_name:'',
            new_entity_location:'',
            edit_entity_name: '',
            edit_entity_location: '',
            edit_id: '',
            encoded_entity_name: '',
            encoded_sp: '',
            deleteEncoded: false,
            delete_encoded_id: '',
            deleteNewEntity: false,
            delete_new_id: ''
		};
	},
    mounted (){
        this.assignData()
    },
    methods: {
        editNewBranch(i){
            this.editNewDialog = true
            this.edit_entity_name = i.account_name
            this.edit_entity_location = i.branch_name
            this.edit_id = i.id
        },
        cancelEdit(){
            this.editNewDialog = false
            this.edit_entity_name = ''
            this.edit_entity_location = ''
            this.edit_id = ''
        },
        addNewHandle(){
            this.handledNewDialog = true
        },
        async submitEdit(){
            const payload = {
                id: this.edit_id,
                finalPayload : {
                    account_name: this.edit_entity_name,
                    branch_name: this.edit_entity_location,
                    handler_name: this.userName,
                    handler_email: this.userEmail,
                }
            }
            const res = await this.$store.dispatch('salesProspecting/doEditNewEntity', {payload})
            if (res.status == 200){
                this.editNewDialog = false
                this.edit_entity_name = ''
                this.edit_entity_location = ''
                this.edit_id = ''
                this.assignData()
            } else if (res.status === 206 || res.code === 400 || res.code === 422){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': res.error ,'messagetype':'error'});
            } else {
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': 'Error Occured!','messagetype':'error'});
            }
        },
        cancelNew(){
            this.handledNewDialog = false
            this.new_entity_name = ''
            this.new_entity_location = ''
        },
        async submitNew(){
            const payload = {
                handler_name: this.userName,
                handler_email: this.userEmail,
                account_name: this.new_entity_name,
                branch_name: this.new_entity_location
            }
            const res = await this.$store.dispatch('salesProspecting/doAddNewEntity', {payload})
            if (res.status == 200){
                this.handledNewDialog = false
                this.new_entity_name = ''
                this.new_entity_location = ''
                this.assignData()
            } else if (res.status === 206 || res.code === 400 || res.code === 422){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': res.error ,'messagetype':'error'});
            } else {
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': 'Error Occured!','messagetype':'error'});
            }
        },
        addHandled(){
            this.handledDialog = true
        },
        async submit(){
            
            const payload = {
                id: this.to_add_branch.id,
                finalPayload : {
                    handler_name: this.userName,
                    handler_email: this.userEmail
                }
            }

            const res = await this.$store.dispatch('salesProspecting/doAddHandledEntity', {payload})
            if (res.status == 200){
                this.encoded_entity_name = ''
                this.encoded_sp = ''
                this.to_add_branch = ''
                this.handledDialog = false
                this.assignData()
            } else if (res.status === 206 || res.code === 400 || res.code === 422){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': res.error ,'messagetype':'error'});
            } else {
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': 'Error Occured!','messagetype':'error'});
            }
        },
        cancel(){
            this.handledDialog = false
            this.to_add_branch = ''
            this.encoded_entity_name = ''
            this.encoded_sp = ''
        },
        fillEncodedDetails(i){
            this.encoded_entity_name = i.account_name
            this.encoded_sp = i.prospective_number
        },
        async assignData(){
            this.branches = []
            const res = await this.$store.dispatch('salesProspecting/doGetProfilingData')
            if (res.status == 200){
            
                this.userName = res.data.result.cluster_details.user_name
                this.userEmail = res.data.result.cluster_details.user_email

                if (res.data.result.cluster_details.cluster_role === 'cluster_head'){
                    this.userRole = 'Cluster Head'
                }
                if (res.data.result.cluster_details.cluster_role === 'roh'){
                    this.userRole = 'Regional Office Head (ROH)'
                }
                if (res.data.result.cluster_details.cluster_role === 'sas'){
                    this.userRole = 'Sub-agent Specialist (SAS)'
                }

                if (res.data.result.encoded_handled_branch.length != 0){
                    res.data.result.encoded_handled_branch.map(item => {
                        this.branches.push(item)
                    })
                }

                if (res.data.result.not_encoded_handled_branch.length != 0){
                    res.data.result.not_encoded_handled_branch.map(item => {
                        this.branches.push(item)
                    })
                }
            }

            const resP = await this.$store.dispatch('salesProspecting/doGetNotHandledBranch')
            if (resP.status == 200){
                this.branchList = resP.data.result
            }

            console.log(this.branchList)
            
        },
        async deleteBranch(i){
            this.deleteEncoded = true
            this.delete_encoded_id = i.id
        },
        async confirmDeleteEncoded(){
            const resP = await this.$store.dispatch('salesProspecting/doDeleteHandledEntity', this.delete_encoded_id)
            if (resP.status == 200){
				this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Removed Successfully.','messagetype':'success'});
                this.deleteEncoded = false
                this.delete_encoded_id = ''
                this.assignData()
            }
        },
        cancelDeleteEncoded(){
            this.deleteEncoded = false
            this.delete_encoded_id = ''
        },
        async deleteNewBranch(i){
            this.deleteNewEntity = true
            this.delete_new_id = i.id
            
        },
        async confirmDeleteNewEntity(){
            const resP = await this.$store.dispatch('salesProspecting/doDeleteNewHandledEntity', this.delete_new_id)
            if (resP.status == 200){
				this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Removed Successfully.','messagetype':'success'});
                this.deleteNewEntity = false
                this.delete_new_id = ''
                this.assignData()
            }
        },
        cancelNewDialog(){
            this.deleteNewEntity = false
            this.delete_new_id = ''
        }
    }

}

</script>
